import CartItemAmount from '@theme/components/cart/CartItemAmount'
import CheckoutItemLine from 'themes/base/components/cart/CheckoutItemLine'
import VariablePrice from 'themes/kolorky/components/cart/VariablePrice'

export default {
  extends: CheckoutItemLine,
  components: {
    VariablePrice,
    CartItemAmount,
  },
  props: {
    charityItem: {
      type: Boolean,
      default: false,
    },
    minQuantity: {
      type: Number,
      default: 1,
    },
    quantityMaxIndividual: {
      type: Number,
    },
  },
  data() {
    return {
      variableItemPrice: this.item.prices.totalWithTax,
    }
  },
  computed: {
    quantityMax() {
      return this.quantityMaxIndividual || CheckoutItemLine.computed.quantityMax.apply(this, arguments)
    },
  },
}
