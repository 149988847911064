import AppImage from '@theme/components/atom/AppImage'
import CheckoutFreeDelivery from '@theme/components/cart/CheckoutFreeDelivery'
import CheckoutFreeGift from '@theme/components/cart/CheckoutFreeGift'
import CheckoutItemLine from '@theme/components/cart/CheckoutItemLine'
import DeliveryDate from '@theme/components/shop/DeliveryDate'
import RemoveFromCart from '@theme/components/cart/RemoveFromCart'

export default {
  components: {
    AppImage,
    CheckoutFreeDelivery,
    CheckoutFreeGift,
    CheckoutItemLine,
    DeliveryDate,
    RemoveFromCart,
  },
  data() {
    return {
      cartItemAmount: null,
      icons: {
        right: require('@icon/right.svg?raw'),
      },
    }
  },
  computed: {
    itemsCount: function() {
      const cartItems = this.$store.state.cart.cartData.items
      if (cartItems) {
        return this.$store.state.cart.cartData.items.reduce((acc, item) => acc + item.amount, 0)
      } else {
        return 0
      }
    },
    settings() {
      return this.$themeSettings.components.CartInfoDropDownContent
    },
    totalPrice: function() {
      return this.$currencyFormat(this.$store.state.cart.cartData.prices.totalWithTax)
    },
  },
  async created() {
    if (this.settings.showCartItemAmount) {
      await this.loadCartItemAmount()
    }
  },
  methods: {
    async loadCartItemAmount() {
      this.cartItemAmount = (await import('@theme/components/cart/CartItemAmount')).default
    },
    findCartItemById(id) {
      let items = [...this.$store.state.cart.cartData.items]

      for (const item of items) {
        if (item.productId === id) {
          return item
        }

        /** Push children to the loop. */
        items.push(...item.items)
      }
    },
  },
}
