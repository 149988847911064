import CheckoutItemLineSecondRowHook from '~/themes/base/components/cart/CheckoutItemLineSecondRowHook'

export default {
  extends: CheckoutItemLineSecondRowHook,
  components: {
    CheckoutItemLine: () => import('~/themes/base/components/cart/CheckoutItemLine'),
  },
  computed: {
    totalItems() {
      return this.item.items.reduce((count, item) => {
        return count + item.amount
      }, 0)
    },
  },
}
