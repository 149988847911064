import CartInfo from '~/themes/base/components/cart/CartInfo'
import InfoCountMixin from '~/mixins/InfoCountMixin'

export default {
  extends: CartInfo,
  mixins: [InfoCountMixin],
  data() {
    return {
      cartPriceVisible: false,
    }
  },
}
