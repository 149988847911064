import MainMenu from '~/themes/base/components/shop/MainMenu'

export default {
  mixins: [MainMenu],
  data() {
    return {
      innerContainerClass: '',
      opacityOnDropdownShown: 0.05,
    }
  },
}
