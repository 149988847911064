import CartInfoDropDownContent from '@theme/components/cart/CartInfoDropDownContent'
import CurrencySwitcher from '@theme/components/shop/CurrencySwitcher'
import HeaderMain from '~/themes/base/components/shop/HeaderMain/HeaderMain.js'
import MainMenu from '@theme/components/shop/MainMenu'
import WishListInfo from '@theme/components/user/WishListInfo'

export default {
  mixins: [HeaderMain],
  components: {
    CartInfoDropDownContent,
    CurrencySwitcher,
    MainMenu,
    WishListInfo,
  },
  props: {
    h1: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      breakpoint: this.$themeSettings.mobileDesktopBreakingPoint,
    }
  },
}
