import { debounce, get } from 'lodash'
import { notifications } from '~/app/notifications'
import AppNumber from '@theme/components/atom/AppNumber'

export default {
  components: { AppNumber },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      required: false,
    },
    item: {
      type: Object,
      required: true,
    },
    min: {
      type: Number,
      default: Infinity,
    },
    local: Boolean,
  },
  data() {
    return {
      loading: false,
    }
  },
  async created() {
    this.debouncedUpdate = debounce(this.updateBackend, 800, {
      leading: false,
      trailing: true,
    })
  },
  methods: {
    async updateBackend(value) {
      if (!this.loading) {
        this.loading = true
        await this.$store.dispatch('cart/UPDATE_ITEM', {
          id: this.item.id,
          quantity: value / this.item.prices.unitWithTax,
        })

        this.loading = false
        this.$emit('changePrice', value)
      }
    },
    findCartItemById(id) {
      let items = [...this.$store.state.cart.cartData.items]

      for (const item of items) {
        if (item.productId === id) {
          return item
        }

        /** Push children to the loop. */
        items.push(...item.items)
      }
    },
    updateValue(val) {
      if (val === '' && !this.local) return

      const valueForUpdate = this.processInputValue(val)

      if (this.local) {
        this.$emit('changePrice', valueForUpdate)
      } else {
        const result = parseInt(val)

        if (result < this.min) {
          notifications.danger(this.$t('VariablePrice.minValueWarning', [this.$currencyFormat(this.min)]))
          return
        }

        this.debouncedUpdate(valueForUpdate)
      }

      this.$refs.numberInput.setElementValue(valueForUpdate)
    },
    processInputValue(val) {
      let result = parseInt(val === '' ? 0 : val)
      result = Math.round(result)
      result = Math.max(result, this.min)

      return result
    },
  },
  computed: {
    decimalPosition() {
      return this.metersParameter ? this.$themeSettings.components.ProductMetersCalculator.decimalPosition || 0 : 0
    },
    metersParameter() {
      const parameters = get(this.item, 'variant.content.parameters') || []
      return parameters.find(parameter => parameter.entityId === this.$contentSettings.metersParameterId)
    },
  },
}
